<template>
  <UsersLayout>
    <UsersForm>
      <template #header>
        <h1 class="users-form__header-title">
          {{ $t('reset_password.title') }}
        </h1>

        <h3 class="users-form__header-subtitle">
          {{ $t('reset_password.subtitle') }}
        </h3>

        <Banner
          :hidden="!showBanner"
          :message="bannerMessage"
          :type="bannerType"
        />
      </template>

      <div class="row">
        <div class="col-12">
          <Input
            v-model="form.password"
            :placeholder="$t('reset_password.placeholders.new_password')"
            :errored="!isPasswordValid && displayInputValidations"
            :required="true"
            type="password"
            @input="hideBanner"
            @keyup.native.enter="submitResetPassword"
          />
          <Input
            v-model="form.passwordConfirmation"
            :placeholder="$t('reset_password.placeholders.confirm_password')"
            :errored="!validPasswordConfirmation && displayInputValidations"
            :required="true"
            type="password"
            @input="hideBanner"
            @keyup.enter.native="submitResetPassword"
          />
          <p
            v-if="form.passwordConfirmation && !validPasswordConfirmation"
            class="password-confirmation--warning"
          >
            {{ $t('warnings.passwords_not_indentical') }}
          </p>

          <div class="user_form__form__password-container">
            <span
              v-for="element in passwordValidationElements"
              :key="element"
            >
              <p :class="passwordCheckClasses(element)">
                <component
                  :is="passwordCheckIcon(element)"
                  :fill="passwordCheckColor(element)"
                  width="40px"
                />
                {{ $t(`warnings.password.${element}`) }}
              </p>
            </span>
          </div>

          <SubmitBtn
            :disabled="!validForm"
            :text="$t('reset_password.button')"
            :spinner="loadingInProgress"
            class="reset-password__submit-btn"
            @click="submitResetPassword"
          />
        </div>
      </div>
    </UsersForm>
  </UsersLayout>
</template>

<script>
// Utils
import { sameValues } from '@static-app/shared/utils/validators';

// Components
import {
  SubmitBtn,
  Banner,
  Input,
} from '@static-ui';
import {
  ClosingXIcon,
  CheckMarkIcon,
} from '@skelloapp/skello-ui';
import {
  isValidPasswordLength,
  isValidPasswordUpper,
  isValidPasswordLower,
  isValidPasswordDigit,
} from '@skello-utils/validators';
import UsersLayout from '../shared/components/Layout';
import UsersForm from '../shared/components/Form';

export default {
  name: 'ResetPassword',
  components: {
    SubmitBtn,
    Banner,
    Input,
    UsersLayout,
    UsersForm,
    ClosingXIcon,
    CheckMarkIcon,
  },
  data() {
    return {
      form: {
        password: '',
        passwordConfirmation: '',
      },
      formSuccessfullySent: false,
      formError: false,
      displayInputValidations: false,
      passwordEqualToCurrent: false,
      loadingInProgress: false,
      isDisabled: true,
      passwordValidationElements: ['length', 'upper', 'lower', 'digit'],
      bannerType: 'error',
    };
  },
  computed: {
    showBanner() {
      return this.formSuccessfullySent || this.formError;
    },
    validForm() {
      return this.isPasswordValid && this.validPasswordConfirmation;
    },
    isPasswordValid() {
      return isValidPasswordLength(this.form.password) &&
        isValidPasswordUpper(this.form.password) &&
        isValidPasswordLower(this.form.password) &&
        isValidPasswordDigit(this.form.password);
    },
    validPasswordConfirmation() {
      return sameValues(this.form.password, this.form.passwordConfirmation);
    },
    bannerMessage() {
      if (this.formSuccessfullySent) {
        return this.$t('reset_password.success_notification');
      }
      if (this.passwordEqualToCurrent) {
        return this.$t('warnings.equal_to_current_password');
      }
      return this.$t('warnings.waited_too_long');
    },
  },
  methods: {
    hideBanner() {
      this.formSuccessfullySent = false;
      this.formError = false;
    },
    checkElementValidity(element) {
      switch (element) {
        case 'length':
          return isValidPasswordLength(this.form.password);
        case 'upper':
          return isValidPasswordUpper(this.form.password);
        case 'lower':
          return isValidPasswordLower(this.form.password);
        case 'digit':
          return isValidPasswordDigit(this.form.password);
        default:
          console.error(`${element} is not checked`);
          return false;
      }
    },
    passwordCheckIcon(element) {
      if (!this.form.password) return CheckMarkIcon;
      return this.checkElementValidity(element) ? CheckMarkIcon : ClosingXIcon;
    },
    passwordCheckClasses(element) {
      return {
        password: true,
        'password--inactive': !this.form.password,
        'password-check--valid': this.form.password && this.checkElementValidity(element),
        'password-check--invalid': this.form.password && !this.checkElementValidity(element),
      };
    },
    passwordCheckColor(element) {
      if (!this.form.password) return '#727272';
      return this.checkElementValidity(element) ? '#188377' : '#d03e50';
    },
    passwordCheckError(err) {
      if (err.errorKey && err.errorKey === 'equal_to_current_password') {
        this.passwordEqualToCurrent = true;
      }
    },
    submitResetPassword() {
      this.displayInputValidations = true;
      if (!this.validForm) {
        this.bannerType = 'error';
        this.formError = true;
        this.formSuccessfullySent = false;
        return;
      }
      this.loadingInProgress = true;
      this.axios
        .patch('/v3/users/password', {
          resource: {
            password: this.form.password,
            reset_password_token: this.$route.query.reset_password_token,
            password_confirmation: this.form.passwordConfirmation,
          },
        },
        )
        .then(response => {
          this.bannerType = 'success';
          this.formSuccessfullySent = true;
          this.formError = false;
          setTimeout(() => {
            window.location.href = response.data.data.redirect_url;
          }, 3000);
        })
        .catch(err => {
          this.passwordCheckError(err.response.data);
          this.formSuccessfullySent = false;
          this.loadingInProgress = false;
          this.bannerType = 'error';
          this.formError = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password__submit-btn {
  font-weight: $fw-regular;
  font-size: $fs-text-m;
  margin-top: 30px;
  width: 100%;
}

.user_form__form__password-container {
  column-count: 2;
  text-align: left;
  margin: 10px 0 25px;

  .password {
    font-size: $fs-text-m;
    font-weight: 400;
    display: inline-block;
    margin-bottom: .3em;
  }

  .password--inactive {
    color: $sk-grey-50;
  }

  .password-check--valid {
    color: $sk-success;
  }

  .password-check--invalid {
    color: $sk-error;
  }
}

.password-confirmation--warning {
  color: $sk-error;
  font-size: $fs-text-xs;
  padding-left: 12px;
  text-align: left;
  position: absolute;
  margin-top: -3px;
}
</style>
